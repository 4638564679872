import React from "react";

const JobOpportunities = () => {
  return (
    <div className="w-[100vw] h-[80vh] text-[30px] flex items-center justify-center ">
      coming soon
    </div>
  );
};

export default JobOpportunities;
